import React from 'react';
import { rscGlobalSnackbar } from 'data';
import { useRecoilState } from 'recoil';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import styles from './GlobalSnackbar.styles';

const GlobalSnackbar = (): React.ReactElement => {
  const [globalSnackbar, setGlobalSnackbar] = useRecoilState(rscGlobalSnackbar);

  if (!globalSnackbar) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!globalSnackbar}
      autoHideDuration={5000}
      onClose={(): void => setGlobalSnackbar(null)}
    >
      <Alert severity={globalSnackbar.severity} variant='filled' sx={styles.alert}>
        {globalSnackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
