export const scaleNumberTo = (num: number, decimal = 3): number => parseFloat(num.toFixed(decimal));

export const m2ft = (meter: number): number => Math.ceil(meter * 3.28084);

export const km2mi = (km: number): string => (km * 0.621371).toFixed(2);

export const metersToKilometers = (meters: number): number => Math.round((meters / 1000) * 100) / 100;
export const metersToMiles = (meters: number): number => Math.round((meters * 100) / 1609) / 100;

export const getDistance = (units = 'si', distance: number, large = true): string => {
  if (units === 'iu') {
    return large ? km2mi(distance) + 'mi' : m2ft(distance) + 'ft';
  }

  return large ? distance.toFixed(2) + 'km' : Math.ceil(distance) + 'm';
};

export const roundNumber = (num: number, dec: number): number => {
  return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
};

export const roundNumbers = (nums: number[], dec: number): number[] => {
  return nums.map((n: number) => roundNumber(n, dec));
};

export const roundNumbers2D = (nums2D: number[][], dec: number): number[][] => {
  return nums2D.map((nums: number[]) => roundNumbers(nums, dec));
};

/** Returns a base 16 string from a base 10 number */
export const getTwoDigitHexString = (decimal: number) => {
  const integerDecimal = Math.floor(decimal);
  if (integerDecimal < 16) {
    return '0' + integerDecimal.toString(16);
  }

  return integerDecimal.toString(16);
};
