import { GeoLocation, LatestPano, Mark, Option, Place } from './common';
import { Station } from './station';

export const INCIDENT_LABEL: Record<string, IncidentLabel> = {
  CLOSED: 'closed',
  DISMISSED: 'dismissed',
  CONFIRMED: 'confirmed',
  PRESCRIBED: 'prescribed',
  POSSIBLE: 'possible',
};

type IncidentLabelOption = {
  value: typeof INCIDENT_LABEL[keyof typeof INCIDENT_LABEL];
  label: string;
};

export const INCIDENT_LABEL_OPTIONS: {
  [key: string]: IncidentLabelOption;
} = {
  CLOSED: { value: INCIDENT_LABEL.CLOSED, label: 'Closed Incident' },
  DISMISSED: { value: INCIDENT_LABEL.DISMISSED, label: 'Dismissed Investigation' },
  CONFIRMED: { value: INCIDENT_LABEL.CONFIRMED, label: 'Alerted Incident' },
  PRESCRIBED: { value: INCIDENT_LABEL.PRESCRIBED, label: 'Controlled Burn' },
  POSSIBLE: { value: INCIDENT_LABEL.POSSIBLE, label: 'Smoke Investigation' },
};

type IncidentType = 'vegetation' | 'structure' | 'car' | 'prescribed' | 'controlled';

export const INCIDENT_TYPE: Record<string, IncidentType> = {
  VEGETATION: 'vegetation',
  STRUCTURE: 'structure',
  CAR: 'car',
  PRESCRIBED: 'prescribed',
  CONTROLLED: 'controlled',
};

type IncidentTypeOption = {
  value: typeof INCIDENT_TYPE[keyof typeof INCIDENT_TYPE];
  label: string;
};

export const INCIDENT_TYPE_OPTIONS: Record<string, IncidentTypeOption> = {
  VEGETATION: { value: INCIDENT_TYPE.VEGETATION, label: 'Vegetation Fire' },
  STRUCTURE: { value: INCIDENT_TYPE.STRUCTURE, label: 'Structure Fire' },
  CAR: { value: INCIDENT_TYPE.CAR, label: 'Car Fire' },
  PRESCRIBED: { value: INCIDENT_TYPE.PRESCRIBED, label: 'Prescribed Fire' },
  CONTROLLED: { value: INCIDENT_TYPE.CONTROLLED, label: 'Controlled Burn' },
};

type IncidentSource = 'dispatch' | 'map' | 'mark';

export const INCIDENT_SOURCE: Record<string, IncidentSource> = {
  DISPATCH: 'dispatch',
  MAP: 'map',
  MARK: 'mark',
};

export type IncidentLabel = 'possible' | 'confirmed' | 'prescribed' | 'dismissed' | 'closed';

export type IncidentCamera = {
  mark?: Mark;
  bearing: number;
  bearingError: number;
  distance: number;
  ordinal: number;
  id: number;
  latestPano?: LatestPano;
  geolocation?: GeoLocation;
};

export type Incident = {
  cameras: IncidentCamera[];
  gpsError?: number;
  id: number;
  name?: string;
  lat?: number;
  lon?: number;
  modifyTime: number;
  place?: Place;
  source: string;
  startTime: number;
  version: number;
  endTime?: number;
  closeTime?: number;
  /** The primary station id. Determines which camera to default to when an incident is triagnulated*/
  primary?: number;
  /** The id of a base incident. Used to link different incidents from the same fire*/
  baseIncidentId?: number;
  // determined at front-end by INCIDENT_ORDERS[getIncidentLabel(incident)]
  order?: number;
  label?: string;
  placeName?: string; // When user is trying to create an incident

  stringified?: string; // A string to represent this incident for faster searching.

  info?: string;
};

export type CreateIncidentPopupInfo = {
  lat: number;
  lon: number;
  placeName: string;
};

// A map: { [incident id]: incident }
export type IncidentsMap = Record<number, Incident>;

export type IncidentFilterOptions = {
  types: Option[];
  labels: Option[];
};

export type IncidentViewOptions = 'standard' | 'with_latest_pano';

export interface GetIncidentsQueryParams {
  /**
   * How many incidents to return
   * @default 1000
   */
  limit?: number;
  /** The statuses of the incidents we want to fetch */
  status?: IncidentLabel[];
  /** The id of the station to return related incidents for  */
  stationId?: Station['id'];
  /**
   * Searches for any of the following
   * Incident ID, Incident name, city, state, county, or country, label ECs
   */
  keyword?: string;
  /**
   * Whether we should skip the generic error handling
   * This is to enable skipping on polling
   */
  shouldSkipErrorHandling?: boolean;
  /**
   * Whether the incident should return the latest pano
   */
  view?: IncidentViewOptions;
  /**
   * The value to use for X-Test-Key header to append to the request for testing
   */
  testHeader?: string;
}

/**
 * The start and end distances from the incidentCamera center of
 * Mapbox polygons that represent an incident
 */
export interface IncidentFeatureLandmarks {
  /**
   * Whether or not the incident camera is calibrated to
   * produce SSILR data and sending location data,
   * if not, show uncalibrated chevrons
   */
  isIncidentCameraCalibrated: boolean;
  /** The start and end location of the grey bearing bar that
   * is before the estimated incident range
   */
  greyBar?: {
    start: number;
    end: number;
  };
  /** The start location of the gradient polygon
   * that indicates incident range uncertainty
   */
  greyToColorFade?: {
    start: number;
    end: number;
  };
  /** The start and end location of the dashed line
   * that indicates an incident is beyond the viewshed
   */
  dottedLine?: {
    start: number;
    end: number;
  };
  /** The start location of the gradient polygon
   * that indicates incident range uncertainty
   */
  transparentToColorFade?: {
    start: number;
    end: number;
  };
  /** The start and end location of the polygon
   * that indicates the range an incident is predicted to be
   */
  solidColorBar?: {
    start: number;
    end: number;
  };
  /** The start of the pointed tip
   * that indicates an incident has an unknown range
   */
  infiniteEndTip?: {
    start: number;
  };
  /** The start location of the gradient polygon
   * that indicates incident range uncertainty
   */
  colorToTransparentFade?: {
    start: number;
    end: number;
  };
  /** The start and end location of the outline and
   * slant decorations for the predicted incident range
   */
  outlineAndSlantDecorations?: {
    fadeInStart?: number;
    start: number;
    end: number;
    fadeOutEnd?: number;
  };
}
