import { rsfFeatureFlags, rsfFetchFeatureFlagsStatus } from 'data';
import { FeatureFlagsApis } from 'data/proxyApi';
import { useRecoilState } from 'recoil';
import { ApiCallStatus, FeatureFlagEvaluation, FeatureFlagValue } from 'types';

import {
  getLocalBooleanFlagOverride,
  getLocalNumberFlagOverride,
  getLocalStringFlagOverride,
} from './useFeatureFlag.helpers';

interface Returned {
  /**
   * Evaluate a boolean feature flag for the currently authenticated user context.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the evaluated boolean value, or the `defaultValue` if the flag could not be evaluated.
   */
  evaluateBooleanFlag: (flagKey: string, defaultValue: boolean) => Promise<boolean>;

  /**
   * Evaluate a string feature flag for the currently authenticated user context.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the evaluated string value, or the `defaultValue` if the flag could not be evaluated.
   */
  evaluateStringFlag: (flagKey: string, defaultValue: string) => Promise<string>;

  /**
   * Evaluate a number feature flag for the currently authenticated user context.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the evaluated number value, or the `defaultValue` if the flag could not be evaluated.
   */
  evaluateNumberFlag: (flagKey: string, defaultValue: number) => Promise<number>;
}

export default function useFeatureFlags(): Returned {
  const [featureFlags, setFeatureFlags] = useRecoilState(rsfFeatureFlags);
  const [fetchFeatureFlagsStatus, setFetchFeatureFlagsStatus] = useRecoilState(rsfFetchFeatureFlagsStatus);

  const evaluateFlag = async <T extends FeatureFlagValue>(
    flagKey: string,
    getFlagEvaluation: () => Promise<FeatureFlagEvaluation<T>>,
  ) => {
    let evaluation = featureFlags[flagKey];

    if (evaluation === undefined && fetchFeatureFlagsStatus[flagKey] !== ApiCallStatus.Pending) {
      setFetchFeatureFlagsStatus((fetchFeatureFlagsStatus) => ({
        ...fetchFeatureFlagsStatus,
        [flagKey]: ApiCallStatus.Pending,
      }));
      evaluation = await getFlagEvaluation();
      setFeatureFlags((featureFlags) => ({
        ...featureFlags,
        [flagKey]: evaluation,
      }));
      setFetchFeatureFlagsStatus({ ...fetchFeatureFlagsStatus, [flagKey]: ApiCallStatus.HasValue });
    }

    return evaluation?.value as T;
  };

  const evaluateBooleanFlag = async (flagKey: string, defaultValue = false) => {
    const overridenValue = getLocalBooleanFlagOverride(flagKey);

    return overridenValue !== null
      ? overridenValue
      : evaluateFlag(flagKey, () => FeatureFlagsApis.apiGetBooleanFeatureFlagEvaluation(flagKey, defaultValue));
  };

  const evaluateStringFlag = async (flagKey: string, defaultValue: string = null) => {
    const overridenValue = getLocalStringFlagOverride(flagKey);

    return overridenValue !== null
      ? overridenValue
      : evaluateFlag(flagKey, () => FeatureFlagsApis.apiGetStringFeatureFlagEvaluation(flagKey, defaultValue));
  };

  const evaluateNumberFlag = async (flagKey: string, defaultValue = 0) => {
    const overridenValue = getLocalNumberFlagOverride(flagKey);

    return overridenValue !== null
      ? overridenValue
      : evaluateFlag(flagKey, () => FeatureFlagsApis.apiGetNumberFeatureFlagEvaluation(flagKey, defaultValue));
  };

  return {
    evaluateBooleanFlag,
    evaluateStringFlag,
    evaluateNumberFlag,
  };
}
