/**
 * This file is for managing incident state that is fetched byId
 * - Mainly to be used on the incident details page
 */

import { IncidentApis } from 'data/proxyApi';
import { rsaUserCoordinateSystem } from 'data/store/authStore';
import { selectorFamily } from 'recoil';
import { CoordinatesForDisplay, CoordinateSystem, Incident, IncidentCamera, IncidentLabel } from 'types';
import { getGDACoordinatesFromLonLat, getIncidentLabel, stringifyGDACoordinates, stringifyLonLat } from 'utils';

import { rssUserStationsMap } from '../../stationStore';
import { rsiUserIncidents } from '../incidentStore';

/**
 * @description Returns the Incident with the given id or token
 * Pulls from rsiUserIncidents, and fetches if not found
 * - NOTE: To just pull without fetching use rsiIncidentById
 */
export const rsiFetchIncidentById = selectorFamily<Incident, number | string>({
  key: 'rsiFetchIncidentById',
  get:
    (
      /** Incident Id or token */
      incidentIdOrToken,
    ) =>
    async ({ get }) => {
      const incidents = get(rsiUserIncidents);

      const incident = incidents?.find((incident) => {
        return incident.id.toString() === incidentIdOrToken.toString();
      });

      if (incident) {
        return incident;
      }

      try {
        const response = await IncidentApis.apiGetIncidentById(incidentIdOrToken);

        return response;
      } catch (err) {
        console.error(err);

        return null;
      }
    },
});

/**
 * @description Returns the cameras for the incident with the given id
 * - Will filter out any cameras the user does not have access to
 * - Will filter out any cameras with no mark
 */
export const rsiIncidentCamerasById = selectorFamily<IncidentCamera[], number>({
  key: 'rsiIncidentCamerasById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const userStationsMap = get(rssUserStationsMap);

      return (
        incident?.cameras?.filter((camera) => !!camera.mark).filter((camera) => userStationsMap[camera?.id]?.name) || []
      );
    },
});

/**
 * @description Returns all available coordinates for the incident
 * - Will return GDA2020 if that GCS is available for any user org
 */
export const rsiIncidentAllCoordinatesById = selectorFamily<CoordinatesForDisplay[], number>({
  key: 'rsiIncidentAllCoordinatesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const coordinateSystem = get(rsaUserCoordinateSystem);

      const lonLat = { lat: incident?.lat, lon: incident?.lon };

      if (!incident || !incident?.lat || !incident?.lon) {
        return [];
      } else if (coordinateSystem === CoordinateSystem.GDA2020) {
        return [
          {
            coordinateSystem: 'UTM (GDA2020)',
            coordinateString: stringifyGDACoordinates(getGDACoordinatesFromLonLat(lonLat)),
          },
          {
            coordinateString: stringifyLonLat(lonLat),
            coordinateSystem: 'GPS (WGS84)',
          },
        ];
      }

      return [
        {
          coordinateString: stringifyLonLat(lonLat),
          coordinateSystem: 'GPS (WGS84)',
        },
      ];
    },
});

/**
 * @description Returns the cameras for the incident with the given id
 * - Will filter out any cameras the user does not have access to
 * - Will filter out any cameras with no mark
 */
export const rsiFetchIncidentLabelById = selectorFamily<IncidentLabel, number>({
  key: 'rsiIncidentCamerasById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));

      return getIncidentLabel(incident);
    },
});
